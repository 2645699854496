"use strict";
exports.__esModule = true;
exports.isWholeSearchIndex = exports.isSearchIndexItem = void 0;
var typescript_json_1 = require("typescript-json");
var isSearchIndexItem = function (obj) { return (function (input) {
    return "object" === typeof input && null !== input && ("string" === typeof input.uniqueId && "string" === typeof input.d && "string" === typeof input.i);
})(obj); };
exports.isSearchIndexItem = isSearchIndexItem;
var isWholeSearchIndex = function (obj) { return (function (input) {
    var $join = typescript_json_1["default"].is.join;
    var $io0 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return "object" === typeof value && null !== value && ("string" === typeof value.uniqueId && "string" === typeof value.d && "string" === typeof value.i);
        return true;
    }); };
    return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
})(obj); };
exports.isWholeSearchIndex = isWholeSearchIndex;
