"use strict";
exports.__esModule = true;
exports.shortHandMapKeys = exports.reverseShortHandMap = exports.shortHandMap = exports.validateDatabase = exports.isWholeDatabase = exports.databaseTypeGuards = void 0;
var typescript_json_1 = require("typescript-json");
var locations_1 = require("./src/locations");
var pathfinding_1 = require("./src/pathfinding");
exports.reverseShortHandMap = pathfinding_1.reverseShortHandMap;
exports.shortHandMap = pathfinding_1.shortHandMap;
exports.shortHandMapKeys = pathfinding_1.shortHandMapKeys;
var providers_1 = require("./src/providers");
var routes_1 = require("./src/routes");
var searchIndex_1 = require("./src/searchIndex");
exports.databaseTypeGuards = {
    providers: providers_1.isProvider,
    locations: locations_1.isLocation,
    routes: routes_1.isRoute,
    pathfinding: pathfinding_1.isPathingPlace,
    searchIndex: searchIndex_1.isSearchIndexItem
};
var isWholeDatabase = function (value) { return (function (input) {
    var $join = typescript_json_1["default"].is.join;
    var $io0 = function (input) { return (undefined === input.providers || "object" === typeof input.providers && null !== input.providers && false === Array.isArray(input.providers) && $io1(input.providers)) && (undefined === input.locations || "object" === typeof input.locations && null !== input.locations && false === Array.isArray(input.locations) && $io6(input.locations)) && (undefined === input.routes || "object" === typeof input.routes && null !== input.routes && false === Array.isArray(input.routes) && $io9(input.routes)) && (undefined === input.pathfinding || "object" === typeof input.pathfinding && null !== input.pathfinding && false === Array.isArray(input.pathfinding) && $io12(input.pathfinding)) && (undefined === input.searchIndex || "object" === typeof input.searchIndex && null !== input.searchIndex && false === Array.isArray(input.searchIndex) && $io16(input.searchIndex)) && (undefined === input.hashes || "object" === typeof input.hashes && null !== input.hashes && $io18(input.hashes)) && (undefined === input.lastImport || "string" === typeof input.lastImport); };
    var $io1 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return "object" === typeof value && null !== value && $io2(value);
        return true;
    }); };
    var $io2 = function (input) { return "string" === typeof input.uniqueId && "string" === typeof input.name && (undefined === input.numberPrefix || "string" === typeof input.numberPrefix) && (undefined === input.description || "string" === typeof input.description) && (undefined === input.logo || "string" === typeof input.logo) && (undefined === input.color || "object" === typeof input.color && null !== input.color && $io3(input.color)) && (null !== input.ownerPlayer && (undefined === input.ownerPlayer || "string" === typeof input.ownerPlayer || Array.isArray(input.ownerPlayer) && input.ownerPlayer.every(function (elem) { return "string" === typeof elem; }))) && (undefined === input.alias || Array.isArray(input.alias) && input.alias.every(function (elem) { return "object" === typeof elem && null !== elem && $io4(elem); })) && "boolean" === typeof input.autoGenerated; };
    var $io3 = function (input) { return "string" === typeof input.light && "string" === typeof input.dark; };
    var $io4 = function (input) { return "string" === typeof input.displayProvider && ("object" === typeof input.numberRange && null !== input.numberRange && ("string" === typeof input.numberRange.start && "string" === typeof input.numberRange.end)); };
    var $io6 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return "object" === typeof value && null !== value && $io7(value);
        return true;
    }); };
    var $io7 = function (input) { return "string" === typeof input.uniqueId && "string" === typeof input.name && "string" === typeof input.shortName && (undefined === input.IATA || "string" === typeof input.IATA) && (undefined === input.description || "string" === typeof input.description) && (undefined === input.location || "object" === typeof input.location && null !== input.location && $io8(input.location)) && (null !== input.ownerPlayer && (undefined === input.ownerPlayer || "string" === typeof input.ownerPlayer || Array.isArray(input.ownerPlayer) && input.ownerPlayer.every(function (elem) { return "string" === typeof elem; }))) && "string" === typeof input.world && "boolean" === typeof input.enabled && "boolean" === typeof input.autoGenerated && ("City" === input.type || "Airport" === input.type || "MRT Station" === input.type || "Other" === input.type || "Coordinate" === input.type) && "boolean" === typeof input.isSpawnWarp && (undefined === input.routes || Array.isArray(input.routes) && input.routes.every(function (elem) { return "string" === typeof elem; })) && (undefined === input.keywords || "string" === typeof input.keywords); };
    var $io8 = function (input) { return "number" === typeof input.x && "number" === typeof input.z && (undefined === input.y || "number" === typeof input.y); };
    var $io9 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return "object" === typeof value && null !== value && $io10(value);
        return true;
    }); };
    var $io10 = function (input) { return "string" === typeof input.uniqueId && (undefined === input.name || "string" === typeof input.name) && (undefined === input.description || "string" === typeof input.description) && ("flight" === input.type || "seaplane" === input.type || "heli" === input.type || "MRT" === input.type || "walk" === input.type || "spawnWarp" === input.type) && (undefined === input.number || "string" === typeof input.number || "number" === typeof input.number) && "string" === typeof input.provider && "boolean" === typeof input.autoGenerated && ("object" === typeof input.locations && null !== input.locations && false === Array.isArray(input.locations) && $io11(input.locations)) && (undefined === input.numGates || "number" === typeof input.numGates); };
    var $io11 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return "string" === typeof value;
        return true;
    }); };
    var $io12 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return "object" === typeof value && null !== value && $io13(value);
        return true;
    }); };
    var $io13 = function (input) { return "string" === typeof input.uniqueId && (undefined === input.x || "number" === typeof input.x) && (undefined === input.z || "number" === typeof input.z) && (undefined === input.w || "boolean" === typeof input.w) && (undefined === input.F || "object" === typeof input.F && null !== input.F && false === Array.isArray(input.F) && $io14(input.F)) && (undefined === input.S || "object" === typeof input.S && null !== input.S && false === Array.isArray(input.S) && $io14(input.S)) && (undefined === input.H || "object" === typeof input.H && null !== input.H && false === Array.isArray(input.H) && $io14(input.H)) && (undefined === input.M || "object" === typeof input.M && null !== input.M && false === Array.isArray(input.M) && $io14(input.M)) && (undefined === input.W || "object" === typeof input.W && null !== input.W && false === Array.isArray(input.W) && $io14(input.W)) && (undefined === input.P || "object" === typeof input.P && null !== input.P && false === Array.isArray(input.P) && $io14(input.P)); };
    var $io14 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return Array.isArray(value) && value.every(function (elem) { return "object" === typeof elem && null !== elem && $io15(elem); });
        return true;
    }); };
    var $io15 = function (input) { return "string" === typeof input.n && (undefined === input.g || "number" === typeof input.g); };
    var $io16 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return "object" === typeof value && null !== value && ("string" === typeof value.uniqueId && "string" === typeof value.d && "string" === typeof value.i);
        return true;
    }); };
    var $io18 = function (input) { return "string" === typeof input.providers && "string" === typeof input.locations && "string" === typeof input.routes && "string" === typeof input.pathfinding && "string" === typeof input.searchIndex; };
    return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
})(value); };
exports.isWholeDatabase = isWholeDatabase;
var validateDatabase = function (value) { return (function (input) {
    var errors = [];
    var $report = typescript_json_1["default"].validate.report(errors);
    var $join = typescript_json_1["default"].validate.join;
    (function (input, _path, _exceptionable) {
        if (_exceptionable === void 0) { _exceptionable = true; }
        var $vo0 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return [undefined === input.providers || ("object" === typeof input.providers && null !== input.providers && false === Array.isArray(input.providers) || $report(_exceptionable, {
                    path: _path + ".providers",
                    expected: "(Resolve<Providers> | undefined)",
                    value: input.providers
                })) && $vo1(input.providers, _path + ".providers", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".providers",
                    expected: "(Resolve<Providers> | undefined)",
                    value: input.providers
                }), undefined === input.locations || ("object" === typeof input.locations && null !== input.locations && false === Array.isArray(input.locations) || $report(_exceptionable, {
                    path: _path + ".locations",
                    expected: "(Resolve<Locations> | undefined)",
                    value: input.locations
                })) && $vo6(input.locations, _path + ".locations", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".locations",
                    expected: "(Resolve<Locations> | undefined)",
                    value: input.locations
                }), undefined === input.routes || ("object" === typeof input.routes && null !== input.routes && false === Array.isArray(input.routes) || $report(_exceptionable, {
                    path: _path + ".routes",
                    expected: "(Resolve<Routes> | undefined)",
                    value: input.routes
                })) && $vo9(input.routes, _path + ".routes", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".routes",
                    expected: "(Resolve<Routes> | undefined)",
                    value: input.routes
                }), undefined === input.pathfinding || ("object" === typeof input.pathfinding && null !== input.pathfinding && false === Array.isArray(input.pathfinding) || $report(_exceptionable, {
                    path: _path + ".pathfinding",
                    expected: "(Resolve<Pathfinding> | undefined)",
                    value: input.pathfinding
                })) && $vo12(input.pathfinding, _path + ".pathfinding", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".pathfinding",
                    expected: "(Resolve<Pathfinding> | undefined)",
                    value: input.pathfinding
                }), undefined === input.searchIndex || ("object" === typeof input.searchIndex && null !== input.searchIndex && false === Array.isArray(input.searchIndex) || $report(_exceptionable, {
                    path: _path + ".searchIndex",
                    expected: "(Resolve<SearchIndex> | undefined)",
                    value: input.searchIndex
                })) && $vo16(input.searchIndex, _path + ".searchIndex", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".searchIndex",
                    expected: "(Resolve<SearchIndex> | undefined)",
                    value: input.searchIndex
                }), undefined === input.hashes || ("object" === typeof input.hashes && null !== input.hashes || $report(_exceptionable, {
                    path: _path + ".hashes",
                    expected: "(Resolve<Partial<Record<\"providers\" | \"locations\" | \"routes\" | \"pathfinding\" | \"searchIndex\", string>>> | undefined)",
                    value: input.hashes
                })) && $vo18(input.hashes, _path + ".hashes", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".hashes",
                    expected: "(Resolve<Partial<Record<\"providers\" | \"locations\" | \"routes\" | \"pathfinding\" | \"searchIndex\", string>>> | undefined)",
                    value: input.hashes
                }), undefined === input.lastImport || "string" === typeof input.lastImport || $report(_exceptionable, {
                    path: _path + ".lastImport",
                    expected: "(string | undefined)",
                    value: input.lastImport
                })].every(function (flag) { return flag; });
        };
        var $vo1 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return [false === _exceptionable || Object.keys(input).map(function (key) {
                    var value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return ("object" === typeof value && null !== value || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<Provider>",
                            value: value
                        })) && $vo2(value, _path + $join(key), true && _exceptionable) || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<Provider>",
                            value: value
                        });
                    return true;
                }).every(function (flag) { return flag; })].every(function (flag) { return flag; });
        };
        var $vo2 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.uniqueId || $report(_exceptionable, {
                    path: _path + ".uniqueId",
                    expected: "string",
                    value: input.uniqueId
                }), "string" === typeof input.name || $report(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }), undefined === input.numberPrefix || "string" === typeof input.numberPrefix || $report(_exceptionable, {
                    path: _path + ".numberPrefix",
                    expected: "(string | undefined)",
                    value: input.numberPrefix
                }), undefined === input.description || "string" === typeof input.description || $report(_exceptionable, {
                    path: _path + ".description",
                    expected: "(string | undefined)",
                    value: input.description
                }), undefined === input.logo || "string" === typeof input.logo || $report(_exceptionable, {
                    path: _path + ".logo",
                    expected: "(string | undefined)",
                    value: input.logo
                }), undefined === input.color || ("object" === typeof input.color && null !== input.color || $report(_exceptionable, {
                    path: _path + ".color",
                    expected: "(Resolve<Color> | undefined)",
                    value: input.color
                })) && $vo3(input.color, _path + ".color", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".color",
                    expected: "(Resolve<Color> | undefined)",
                    value: input.color
                }), (null !== input.ownerPlayer || $report(_exceptionable, {
                    path: _path + ".ownerPlayer",
                    expected: "(Array<string> | string | undefined)",
                    value: input.ownerPlayer
                })) && (undefined === input.ownerPlayer || "string" === typeof input.ownerPlayer || (Array.isArray(input.ownerPlayer) || $report(_exceptionable, {
                    path: _path + ".ownerPlayer",
                    expected: "(Array<string> | string | undefined)",
                    value: input.ownerPlayer
                })) && input.ownerPlayer.map(function (elem, _index1) { return "string" === typeof elem || $report(_exceptionable, {
                    path: _path + ".ownerPlayer[" + _index1 + "]",
                    expected: "string",
                    value: elem
                }); }).every(function (flag) { return flag; }) || $report(_exceptionable, {
                    path: _path + ".ownerPlayer",
                    expected: "(Array<string> | string | undefined)",
                    value: input.ownerPlayer
                })), undefined === input.alias || (Array.isArray(input.alias) || $report(_exceptionable, {
                    path: _path + ".alias",
                    expected: "(Array<Resolve<Alias>> | undefined)",
                    value: input.alias
                })) && input.alias.map(function (elem, _index2) { return ("object" === typeof elem && null !== elem || $report(_exceptionable, {
                    path: _path + ".alias[" + _index2 + "]",
                    expected: "Resolve<Alias>",
                    value: elem
                })) && $vo4(elem, _path + ".alias[" + _index2 + "]", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".alias[" + _index2 + "]",
                    expected: "Resolve<Alias>",
                    value: elem
                }); }).every(function (flag) { return flag; }) || $report(_exceptionable, {
                    path: _path + ".alias",
                    expected: "(Array<Resolve<Alias>> | undefined)",
                    value: input.alias
                }), "boolean" === typeof input.autoGenerated || $report(_exceptionable, {
                    path: _path + ".autoGenerated",
                    expected: "boolean",
                    value: input.autoGenerated
                })].every(function (flag) { return flag; });
        };
        var $vo3 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.light || $report(_exceptionable, {
                    path: _path + ".light",
                    expected: "string",
                    value: input.light
                }), "string" === typeof input.dark || $report(_exceptionable, {
                    path: _path + ".dark",
                    expected: "string",
                    value: input.dark
                })].every(function (flag) { return flag; });
        };
        var $vo4 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.displayProvider || $report(_exceptionable, {
                    path: _path + ".displayProvider",
                    expected: "string",
                    value: input.displayProvider
                }), ("object" === typeof input.numberRange && null !== input.numberRange || $report(_exceptionable, {
                    path: _path + ".numberRange",
                    expected: "Resolve<NumberRange>",
                    value: input.numberRange
                })) && $vo5(input.numberRange, _path + ".numberRange", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".numberRange",
                    expected: "Resolve<NumberRange>",
                    value: input.numberRange
                })].every(function (flag) { return flag; });
        };
        var $vo5 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.start || $report(_exceptionable, {
                    path: _path + ".start",
                    expected: "string",
                    value: input.start
                }), "string" === typeof input.end || $report(_exceptionable, {
                    path: _path + ".end",
                    expected: "string",
                    value: input.end
                })].every(function (flag) { return flag; });
        };
        var $vo6 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return [false === _exceptionable || Object.keys(input).map(function (key) {
                    var value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return ("object" === typeof value && null !== value || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<Location>",
                            value: value
                        })) && $vo7(value, _path + $join(key), true && _exceptionable) || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<Location>",
                            value: value
                        });
                    return true;
                }).every(function (flag) { return flag; })].every(function (flag) { return flag; });
        };
        var $vo7 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.uniqueId || $report(_exceptionable, {
                    path: _path + ".uniqueId",
                    expected: "string",
                    value: input.uniqueId
                }), "string" === typeof input.name || $report(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }), "string" === typeof input.shortName || $report(_exceptionable, {
                    path: _path + ".shortName",
                    expected: "string",
                    value: input.shortName
                }), undefined === input.IATA || "string" === typeof input.IATA || $report(_exceptionable, {
                    path: _path + ".IATA",
                    expected: "(string | undefined)",
                    value: input.IATA
                }), undefined === input.description || "string" === typeof input.description || $report(_exceptionable, {
                    path: _path + ".description",
                    expected: "(string | undefined)",
                    value: input.description
                }), undefined === input.location || ("object" === typeof input.location && null !== input.location || $report(_exceptionable, {
                    path: _path + ".location",
                    expected: "(Resolve<Coordinates> | undefined)",
                    value: input.location
                })) && $vo8(input.location, _path + ".location", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".location",
                    expected: "(Resolve<Coordinates> | undefined)",
                    value: input.location
                }), (null !== input.ownerPlayer || $report(_exceptionable, {
                    path: _path + ".ownerPlayer",
                    expected: "(Array<string> | string | undefined)",
                    value: input.ownerPlayer
                })) && (undefined === input.ownerPlayer || "string" === typeof input.ownerPlayer || (Array.isArray(input.ownerPlayer) || $report(_exceptionable, {
                    path: _path + ".ownerPlayer",
                    expected: "(Array<string> | string | undefined)",
                    value: input.ownerPlayer
                })) && input.ownerPlayer.map(function (elem, _index3) { return "string" === typeof elem || $report(_exceptionable, {
                    path: _path + ".ownerPlayer[" + _index3 + "]",
                    expected: "string",
                    value: elem
                }); }).every(function (flag) { return flag; }) || $report(_exceptionable, {
                    path: _path + ".ownerPlayer",
                    expected: "(Array<string> | string | undefined)",
                    value: input.ownerPlayer
                })), "string" === typeof input.world || $report(_exceptionable, {
                    path: _path + ".world",
                    expected: "string",
                    value: input.world
                }), "boolean" === typeof input.enabled || $report(_exceptionable, {
                    path: _path + ".enabled",
                    expected: "boolean",
                    value: input.enabled
                }), "boolean" === typeof input.autoGenerated || $report(_exceptionable, {
                    path: _path + ".autoGenerated",
                    expected: "boolean",
                    value: input.autoGenerated
                }), "City" === input.type || "Airport" === input.type || "MRT Station" === input.type || "Other" === input.type || "Coordinate" === input.type || $report(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"Airport\" | \"City\" | \"Coordinate\" | \"MRT Station\" | \"Other\")",
                    value: input.type
                }), "boolean" === typeof input.isSpawnWarp || $report(_exceptionable, {
                    path: _path + ".isSpawnWarp",
                    expected: "boolean",
                    value: input.isSpawnWarp
                }), undefined === input.routes || (Array.isArray(input.routes) || $report(_exceptionable, {
                    path: _path + ".routes",
                    expected: "(Array<string> | undefined)",
                    value: input.routes
                })) && input.routes.map(function (elem, _index4) { return "string" === typeof elem || $report(_exceptionable, {
                    path: _path + ".routes[" + _index4 + "]",
                    expected: "string",
                    value: elem
                }); }).every(function (flag) { return flag; }) || $report(_exceptionable, {
                    path: _path + ".routes",
                    expected: "(Array<string> | undefined)",
                    value: input.routes
                }), undefined === input.keywords || "string" === typeof input.keywords || $report(_exceptionable, {
                    path: _path + ".keywords",
                    expected: "(string | undefined)",
                    value: input.keywords
                })].every(function (flag) { return flag; });
        };
        var $vo8 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["number" === typeof input.x || $report(_exceptionable, {
                    path: _path + ".x",
                    expected: "number",
                    value: input.x
                }), "number" === typeof input.z || $report(_exceptionable, {
                    path: _path + ".z",
                    expected: "number",
                    value: input.z
                }), undefined === input.y || "number" === typeof input.y || $report(_exceptionable, {
                    path: _path + ".y",
                    expected: "(number | undefined)",
                    value: input.y
                })].every(function (flag) { return flag; });
        };
        var $vo9 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return [false === _exceptionable || Object.keys(input).map(function (key) {
                    var value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return ("object" === typeof value && null !== value || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<Route>",
                            value: value
                        })) && $vo10(value, _path + $join(key), true && _exceptionable) || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<Route>",
                            value: value
                        });
                    return true;
                }).every(function (flag) { return flag; })].every(function (flag) { return flag; });
        };
        var $vo10 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.uniqueId || $report(_exceptionable, {
                    path: _path + ".uniqueId",
                    expected: "string",
                    value: input.uniqueId
                }), undefined === input.name || "string" === typeof input.name || $report(_exceptionable, {
                    path: _path + ".name",
                    expected: "(string | undefined)",
                    value: input.name
                }), undefined === input.description || "string" === typeof input.description || $report(_exceptionable, {
                    path: _path + ".description",
                    expected: "(string | undefined)",
                    value: input.description
                }), "flight" === input.type || "seaplane" === input.type || "heli" === input.type || "MRT" === input.type || "walk" === input.type || "spawnWarp" === input.type || $report(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"MRT\" | \"flight\" | \"heli\" | \"seaplane\" | \"spawnWarp\" | \"walk\")",
                    value: input.type
                }), undefined === input.number || "string" === typeof input.number || "number" === typeof input.number || $report(_exceptionable, {
                    path: _path + ".number",
                    expected: "(number | string | undefined)",
                    value: input.number
                }), "string" === typeof input.provider || $report(_exceptionable, {
                    path: _path + ".provider",
                    expected: "string",
                    value: input.provider
                }), "boolean" === typeof input.autoGenerated || $report(_exceptionable, {
                    path: _path + ".autoGenerated",
                    expected: "boolean",
                    value: input.autoGenerated
                }), ("object" === typeof input.locations && null !== input.locations && false === Array.isArray(input.locations) || $report(_exceptionable, {
                    path: _path + ".locations",
                    expected: "Resolve<RouteLocations>",
                    value: input.locations
                })) && $vo11(input.locations, _path + ".locations", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".locations",
                    expected: "Resolve<RouteLocations>",
                    value: input.locations
                }), undefined === input.numGates || "number" === typeof input.numGates || $report(_exceptionable, {
                    path: _path + ".numGates",
                    expected: "(number | undefined)",
                    value: input.numGates
                })].every(function (flag) { return flag; });
        };
        var $vo11 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return [false === _exceptionable || Object.keys(input).map(function (key) {
                    var value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return "string" === typeof value || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "string",
                            value: value
                        });
                    return true;
                }).every(function (flag) { return flag; })].every(function (flag) { return flag; });
        };
        var $vo12 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return [false === _exceptionable || Object.keys(input).map(function (key) {
                    var value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return ("object" === typeof value && null !== value || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<PathingPlace>",
                            value: value
                        })) && $vo13(value, _path + $join(key), true && _exceptionable) || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<PathingPlace>",
                            value: value
                        });
                    return true;
                }).every(function (flag) { return flag; })].every(function (flag) { return flag; });
        };
        var $vo13 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.uniqueId || $report(_exceptionable, {
                    path: _path + ".uniqueId",
                    expected: "string",
                    value: input.uniqueId
                }), undefined === input.x || "number" === typeof input.x || $report(_exceptionable, {
                    path: _path + ".x",
                    expected: "(number | undefined)",
                    value: input.x
                }), undefined === input.z || "number" === typeof input.z || $report(_exceptionable, {
                    path: _path + ".z",
                    expected: "(number | undefined)",
                    value: input.z
                }), undefined === input.w || "boolean" === typeof input.w || $report(_exceptionable, {
                    path: _path + ".w",
                    expected: "(boolean | undefined)",
                    value: input.w
                }), undefined === input.F || ("object" === typeof input.F && null !== input.F && false === Array.isArray(input.F) || $report(_exceptionable, {
                    path: _path + ".F",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.F
                })) && $vo14(input.F, _path + ".F", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".F",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.F
                }), undefined === input.S || ("object" === typeof input.S && null !== input.S && false === Array.isArray(input.S) || $report(_exceptionable, {
                    path: _path + ".S",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.S
                })) && $vo14(input.S, _path + ".S", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".S",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.S
                }), undefined === input.H || ("object" === typeof input.H && null !== input.H && false === Array.isArray(input.H) || $report(_exceptionable, {
                    path: _path + ".H",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.H
                })) && $vo14(input.H, _path + ".H", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".H",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.H
                }), undefined === input.M || ("object" === typeof input.M && null !== input.M && false === Array.isArray(input.M) || $report(_exceptionable, {
                    path: _path + ".M",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.M
                })) && $vo14(input.M, _path + ".M", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".M",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.M
                }), undefined === input.W || ("object" === typeof input.W && null !== input.W && false === Array.isArray(input.W) || $report(_exceptionable, {
                    path: _path + ".W",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.W
                })) && $vo14(input.W, _path + ".W", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".W",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.W
                }), undefined === input.P || ("object" === typeof input.P && null !== input.P && false === Array.isArray(input.P) || $report(_exceptionable, {
                    path: _path + ".P",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.P
                })) && $vo14(input.P, _path + ".P", true && _exceptionable) || $report(_exceptionable, {
                    path: _path + ".P",
                    expected: "(Resolve<ShortHandType> | undefined)",
                    value: input.P
                })].every(function (flag) { return flag; });
        };
        var $vo14 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return [false === _exceptionable || Object.keys(input).map(function (key) {
                    var value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return (Array.isArray(value) || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Array<Resolve<__type>>",
                            value: value
                        })) && value.map(function (elem, _index5) { return ("object" === typeof elem && null !== elem || $report(_exceptionable, {
                            path: _path + $join(key) + "[" + _index5 + "]",
                            expected: "Resolve<__type>",
                            value: elem
                        })) && $vo15(elem, _path + $join(key) + "[" + _index5 + "]", true && _exceptionable) || $report(_exceptionable, {
                            path: _path + $join(key) + "[" + _index5 + "]",
                            expected: "Resolve<__type>",
                            value: elem
                        }); }).every(function (flag) { return flag; }) || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Array<Resolve<__type>>",
                            value: value
                        });
                    return true;
                }).every(function (flag) { return flag; })].every(function (flag) { return flag; });
        };
        var $vo15 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.n || $report(_exceptionable, {
                    path: _path + ".n",
                    expected: "string",
                    value: input.n
                }), undefined === input.g || "number" === typeof input.g || $report(_exceptionable, {
                    path: _path + ".g",
                    expected: "(number | undefined)",
                    value: input.g
                })].every(function (flag) { return flag; });
        };
        var $vo16 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return [false === _exceptionable || Object.keys(input).map(function (key) {
                    var value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return ("object" === typeof value && null !== value || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<SearchIndexItem>",
                            value: value
                        })) && $vo17(value, _path + $join(key), true && _exceptionable) || $report(_exceptionable, {
                            path: _path + $join(key),
                            expected: "Resolve<SearchIndexItem>",
                            value: value
                        });
                    return true;
                }).every(function (flag) { return flag; })].every(function (flag) { return flag; });
        };
        var $vo17 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.uniqueId || $report(_exceptionable, {
                    path: _path + ".uniqueId",
                    expected: "string",
                    value: input.uniqueId
                }), "string" === typeof input.d || $report(_exceptionable, {
                    path: _path + ".d",
                    expected: "string",
                    value: input.d
                }), "string" === typeof input.i || $report(_exceptionable, {
                    path: _path + ".i",
                    expected: "string",
                    value: input.i
                })].every(function (flag) { return flag; });
        };
        var $vo18 = function (input, _path, _exceptionable) {
            if (_exceptionable === void 0) { _exceptionable = true; }
            return ["string" === typeof input.providers || $report(_exceptionable, {
                    path: _path + ".providers",
                    expected: "string",
                    value: input.providers
                }), "string" === typeof input.locations || $report(_exceptionable, {
                    path: _path + ".locations",
                    expected: "string",
                    value: input.locations
                }), "string" === typeof input.routes || $report(_exceptionable, {
                    path: _path + ".routes",
                    expected: "string",
                    value: input.routes
                }), "string" === typeof input.pathfinding || $report(_exceptionable, {
                    path: _path + ".pathfinding",
                    expected: "string",
                    value: input.pathfinding
                }), "string" === typeof input.searchIndex || $report(_exceptionable, {
                    path: _path + ".searchIndex",
                    expected: "string",
                    value: input.searchIndex
                })].every(function (flag) { return flag; });
        };
        return ("object" === typeof input && null !== input && false === Array.isArray(input) || $report(true, {
            path: _path + "",
            expected: "Resolve<DatabaseType>",
            value: input
        })) && $vo0(input, _path + "", true) || $report(true, {
            path: _path + "",
            expected: "Resolve<DatabaseType>",
            value: input
        });
    })(input, "$input", true);
    var success = 0 === errors.length;
    return {
        success: success,
        errors: errors,
        data: success ? input : undefined
    };
})(value); };
exports.validateDatabase = validateDatabase;
