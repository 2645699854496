/*  eslint-disable no-console */
import {
  Hashes,
  databaseTypeGuards,
  Location,
  DatabaseDataKeys,
  DataDatabaseType,
} from "@rapidroute/database-types"

import { setShowOfflineBanner } from "components/OfflineBanner"
import { isBrowser, sleep } from "utils/functions"
import isObject from "utils/isObject"
import { getLocal, setLocal } from "utils/localUtils"
import { wrap } from "utils/promise-worker"

import { FirebaseWorkerFunctions } from "./firebase"
import isCoordinate from "./isCoordinate"

const defaultDatabaseCache: DataDatabaseType = {
  locations: {},
  pathfinding: {},
  providers: {},
  routes: {},
  searchIndex: {},
}
const defaultHashes: Hashes = {
  locations: undefined,
  pathfinding: undefined,
  providers: undefined,
  routes: undefined,
  searchIndex: undefined,
}
const databaseCache = getLocal("databaseCache") ?? defaultDatabaseCache
const oneHashes = getLocal("oneHash") ?? defaultHashes
const allHashes = getLocal("allHash") ?? defaultHashes

const firebaseWorkerRaw = (async () => {
  if (!isBrowser()) return undefined
  const worker = new Worker(new URL("./firebase.ts", import.meta.url))
  return wrap<FirebaseWorkerFunctions>(worker)
})()

let databaseHashes: Hashes = {
  locations: "",
  pathfinding: "",
  providers: "",
  routes: "",
  searchIndex: "",
}
const hashesExist = new Promise(resolve => {
  // getData("hashes", rawValue => {
  //   if (isObject(rawValue)) {
  //     databaseHashes = { ...defaultHashes, ...rawValue }
  //   }
  //   resolve(true)
  // })
  firebaseWorkerRaw
    .then(async worker => {
      if (worker) {
        const rawValue = await worker.getData("hashes")
        if (isObject(rawValue)) {
          databaseHashes = { ...defaultHashes, ...rawValue }
          resolve(true)
        }
      }
    })
    .catch(() => {
      resolve(true)
    })
})

type GetAll<T extends DatabaseDataKeys> = NonNullable<DataDatabaseType[T]>
type GetOne<T extends DatabaseDataKeys> = NonNullable<
  DataDatabaseType[T]
>[string]

async function getPathFromDatabase<T extends DatabaseDataKeys>(
  type: T,
  itemName: string
): Promise<GetOne<T> | null> {
  const firebaseWorker = await firebaseWorkerRaw
  if (!firebaseWorker) return null
  const dataIn = await firebaseWorker.getData(`${type}/${itemName}`)
  const data = isObject(dataIn) ? { ...dataIn, uniqueId: itemName } : dataIn
  if (!databaseTypeGuards[type](data)) {
    console.log("guard failed", type, data)
    return null
  }
  databaseCache[type] = {
    ...databaseCache[type],
    [itemName]: data,
  }
  return data
}

async function getAllFromDatabase<T extends DatabaseDataKeys>(
  type: T
): Promise<GetAll<T>> {
  console.log("getAllFromDatabase", type)
  const firebaseWorker = await firebaseWorkerRaw
  if (!firebaseWorker) return {}
  const rawData = await firebaseWorker.getData(type)
  const data: GetAll<T> = {}

  if (isObject(rawData)) {
    // for each item, add the uniqueId
    Object.keys(rawData).forEach(key => {
      const item = rawData[key]
      if (isObject(item)) item.uniqueId = key
    })

    // filter out values that don't match the type guard
    Object.entries(rawData).forEach(([key, item]) => {
      // if key is a symbol, it's not a valid key
      if (typeof key === "symbol") return

      if (databaseTypeGuards[type](item)) {
        data[key] = item
      } else {
        console.log("guard failed", type, item)
      }
    })
  }

  return data
}

const fetchingPaths: string[] = []

export async function getPath<T extends DatabaseDataKeys>(
  type: T,
  itemName: string
): Promise<GetOne<T> | null> {
  while (fetchingPaths.includes(`${type}/${itemName}`)) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(250)
  }
  const timeout = setTimeout(() => {
    setShowOfflineBanner(true)
  }, 10000)
  fetchingPaths.push(`${type}/${itemName}`)
  const done = () => {
    fetchingPaths.splice(fetchingPaths.indexOf(`${type}/${itemName}`), 1)
    clearTimeout(timeout)
  }

  // some things are not in the database, so we need to check for that
  const coordData = isCoordinate(itemName)
  if (type === "locations" && coordData) {
    const xCoord = coordData.x
    const zCoord = coordData.z
    const out: Location = {
      uniqueId: itemName,
      name: itemName,
      shortName: `${xCoord}, ${zCoord}`,
      autoGenerated: true,
      type: "Coordinate",
      world: "New",
      enabled: true,
      isSpawnWarp: false,
      location: {
        x: xCoord,
        z: zCoord,
      },
    }
    if (databaseTypeGuards[type](out)) {
      done()
      return out
    }
  }
  if (type === "locations" && itemName === "Current Location") {
    const out: Location = {
      uniqueId: itemName,
      name: itemName,
      shortName: "Location",
      autoGenerated: true,
      type: "Coordinate",
      world: "New",
      enabled: true,
      isSpawnWarp: false,
    }
    if (databaseTypeGuards[type](out)) {
      done()
      return out
    }
  }

  // first get the hash from the database
  await hashesExist
  const hash = databaseHashes[type]

  // if the hash matches the one we have, return the cached value
  if (hash === oneHashes[type] && databaseCache[type]?.[itemName]) {
    console.log("cache hit", type, itemName)
    const output = databaseCache[type]?.[itemName]
    if (databaseTypeGuards[type](output)) {
      done()
      return output
    }
    console.log("guard failed", type, output)
  }
  if (hash !== oneHashes[type]) {
    console.log("hash mismatch", type, itemName)

    // clear the cache
    databaseCache[type] = {}
  } else {
    console.log("cache miss", type, itemName)
  }

  const path = await getPathFromDatabase(type, itemName)
  oneHashes[type] = hash
  setLocal("databaseCache", databaseCache)
  setLocal("oneHash", oneHashes)
  done()
  return path
}

export async function getAll<T extends DatabaseDataKeys>(
  type: T
): Promise<GetAll<T>> {
  while (fetchingPaths.includes(type)) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(250)
  }
  fetchingPaths.push(type)
  const timeout = setTimeout(() => {
    setShowOfflineBanner(true)
  }, 10000)
  const done = () => {
    fetchingPaths.splice(fetchingPaths.indexOf(type), 1)
    clearTimeout(timeout)
  }

  console.log("getAll", type)
  // first get the hash from the database
  await hashesExist
  const hash = databaseHashes[type]

  // if the hash matches the one we have, return the cached value
  if (hash === allHashes[type] && databaseCache[type]) {
    console.log("cache hit", type)
    const output: GetAll<T> = databaseCache[type] ?? {}

    // filter out values that don't match the type guard
    Object.keys(output).forEach(key => {
      if (!databaseTypeGuards[type](output[key])) {
        console.log("guard failed", type, output[key])
        delete output[key]
      }
    })
    done()
    return output
  }
  if (hash !== allHashes[type]) {
    console.log("hash mismatch", type)

    // clear the cache
    databaseCache[type] = {}
  } else {
    console.log("cache miss", type)
  }

  const data = await getAllFromDatabase(type)
  databaseCache[type] = data
  allHashes[type] = hash
  oneHashes[type] = hash
  setLocal("databaseCache", databaseCache)
  setLocal("allHash", allHashes)
  setLocal("oneHash", oneHashes)
  done()
  return data
}
