"use strict";
exports.__esModule = true;
exports.isWholePathfinding = exports.isPathingPlace = exports.shortHandMapKeys = exports.reverseShortHandMap = exports.shortHandMap = void 0;
var typescript_json_1 = require("typescript-json");
exports.shortHandMap = {
    F: "flight",
    S: "seaplane",
    H: "heli",
    M: "MRT",
    W: "walk",
    P: "spawnWarp"
};
exports.reverseShortHandMap = {
    flight: "F",
    seaplane: "S",
    heli: "H",
    MRT: "M",
    walk: "W",
    spawnWarp: "P"
};
exports.shortHandMapKeys = ["F", "S", "H", "M", "W", "P"];
var isPathingPlace = function (obj) { return (function (input) {
    var $join = typescript_json_1["default"].is.join;
    var $io0 = function (input) { return "string" === typeof input.uniqueId && (undefined === input.x || "number" === typeof input.x) && (undefined === input.z || "number" === typeof input.z) && (undefined === input.w || "boolean" === typeof input.w) && (undefined === input.F || "object" === typeof input.F && null !== input.F && false === Array.isArray(input.F) && $io1(input.F)) && (undefined === input.S || "object" === typeof input.S && null !== input.S && false === Array.isArray(input.S) && $io1(input.S)) && (undefined === input.H || "object" === typeof input.H && null !== input.H && false === Array.isArray(input.H) && $io1(input.H)) && (undefined === input.M || "object" === typeof input.M && null !== input.M && false === Array.isArray(input.M) && $io1(input.M)) && (undefined === input.W || "object" === typeof input.W && null !== input.W && false === Array.isArray(input.W) && $io1(input.W)) && (undefined === input.P || "object" === typeof input.P && null !== input.P && false === Array.isArray(input.P) && $io1(input.P)); };
    var $io1 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return Array.isArray(value) && value.every(function (elem) { return "object" === typeof elem && null !== elem && $io2(elem); });
        return true;
    }); };
    var $io2 = function (input) { return "string" === typeof input.n && (undefined === input.g || "number" === typeof input.g); };
    return "object" === typeof input && null !== input && $io0(input);
})(obj); };
exports.isPathingPlace = isPathingPlace;
var isWholePathfinding = function (obj) { return (function (input) {
    var $join = typescript_json_1["default"].is.join;
    var $io0 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return "object" === typeof value && null !== value && $io1(value);
        return true;
    }); };
    var $io1 = function (input) { return "string" === typeof input.uniqueId && (undefined === input.x || "number" === typeof input.x) && (undefined === input.z || "number" === typeof input.z) && (undefined === input.w || "boolean" === typeof input.w) && (undefined === input.F || "object" === typeof input.F && null !== input.F && false === Array.isArray(input.F) && $io2(input.F)) && (undefined === input.S || "object" === typeof input.S && null !== input.S && false === Array.isArray(input.S) && $io2(input.S)) && (undefined === input.H || "object" === typeof input.H && null !== input.H && false === Array.isArray(input.H) && $io2(input.H)) && (undefined === input.M || "object" === typeof input.M && null !== input.M && false === Array.isArray(input.M) && $io2(input.M)) && (undefined === input.W || "object" === typeof input.W && null !== input.W && false === Array.isArray(input.W) && $io2(input.W)) && (undefined === input.P || "object" === typeof input.P && null !== input.P && false === Array.isArray(input.P) && $io2(input.P)); };
    var $io2 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return Array.isArray(value) && value.every(function (elem) { return "object" === typeof elem && null !== elem && $io3(elem); });
        return true;
    }); };
    var $io3 = function (input) { return "string" === typeof input.n && (undefined === input.g || "number" === typeof input.g); };
    return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
})(obj); };
exports.isWholePathfinding = isWholePathfinding;
