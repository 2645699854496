"use strict";
exports.__esModule = true;
exports.isProvider = void 0;
var typescript_json_1 = require("typescript-json");
var isProvider = function (obj) { return (function (input) {
    var $io0 = function (input) { return "string" === typeof input.uniqueId && "string" === typeof input.name && (undefined === input.numberPrefix || "string" === typeof input.numberPrefix) && (undefined === input.description || "string" === typeof input.description) && (undefined === input.logo || "string" === typeof input.logo) && (undefined === input.color || "object" === typeof input.color && null !== input.color && $io1(input.color)) && (null !== input.ownerPlayer && (undefined === input.ownerPlayer || "string" === typeof input.ownerPlayer || Array.isArray(input.ownerPlayer) && input.ownerPlayer.every(function (elem) { return "string" === typeof elem; }))) && (undefined === input.alias || Array.isArray(input.alias) && input.alias.every(function (elem) { return "object" === typeof elem && null !== elem && $io2(elem); })) && "boolean" === typeof input.autoGenerated; };
    var $io1 = function (input) { return "string" === typeof input.light && "string" === typeof input.dark; };
    var $io2 = function (input) { return "string" === typeof input.displayProvider && ("object" === typeof input.numberRange && null !== input.numberRange && ("string" === typeof input.numberRange.start && "string" === typeof input.numberRange.end)); };
    return "object" === typeof input && null !== input && $io0(input);
})(obj); };
exports.isProvider = isProvider;
