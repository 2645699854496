"use strict";
exports.__esModule = true;
exports.isLocation = void 0;
var typescript_json_1 = require("typescript-json");
var isLocation = function (obj) { return (function (input) {
    var $io0 = function (input) { return "string" === typeof input.uniqueId && "string" === typeof input.name && "string" === typeof input.shortName && (undefined === input.IATA || "string" === typeof input.IATA) && (undefined === input.description || "string" === typeof input.description) && (undefined === input.location || "object" === typeof input.location && null !== input.location && $io1(input.location)) && (null !== input.ownerPlayer && (undefined === input.ownerPlayer || "string" === typeof input.ownerPlayer || Array.isArray(input.ownerPlayer) && input.ownerPlayer.every(function (elem) { return "string" === typeof elem; }))) && "string" === typeof input.world && "boolean" === typeof input.enabled && "boolean" === typeof input.autoGenerated && ("City" === input.type || "Airport" === input.type || "MRT Station" === input.type || "Other" === input.type || "Coordinate" === input.type) && "boolean" === typeof input.isSpawnWarp && (undefined === input.routes || Array.isArray(input.routes) && input.routes.every(function (elem) { return "string" === typeof elem; })) && (undefined === input.keywords || "string" === typeof input.keywords); };
    var $io1 = function (input) { return "number" === typeof input.x && "number" === typeof input.z && (undefined === input.y || "number" === typeof input.y); };
    return "object" === typeof input && null !== input && $io0(input);
})(obj); };
exports.isLocation = isLocation;
