"use strict";
exports.__esModule = true;
exports.isRoute = void 0;
var typescript_json_1 = require("typescript-json");
var isRoute = function (obj) { return (function (input) {
    var $join = typescript_json_1["default"].is.join;
    var $io0 = function (input) { return "string" === typeof input.uniqueId && (undefined === input.name || "string" === typeof input.name) && (undefined === input.description || "string" === typeof input.description) && ("flight" === input.type || "seaplane" === input.type || "heli" === input.type || "MRT" === input.type || "walk" === input.type || "spawnWarp" === input.type) && (undefined === input.number || "string" === typeof input.number || "number" === typeof input.number) && "string" === typeof input.provider && "boolean" === typeof input.autoGenerated && ("object" === typeof input.locations && null !== input.locations && false === Array.isArray(input.locations) && $io1(input.locations)) && (undefined === input.numGates || "number" === typeof input.numGates); };
    var $io1 = function (input) { return Object.keys(input).every(function (key) {
        var value = input[key];
        if (undefined === value)
            return true;
        if (RegExp(/(.*)/).test(key))
            return "string" === typeof value;
        return true;
    }); };
    return "object" === typeof input && null !== input && $io0(input);
})(obj); };
exports.isRoute = isRoute;
