module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MRT RapidRoute","short_name":"RapidRoute","start_url":"/","display":"standalone","display_override":["window-controls-overlay"],"background_color":"#111111","theme_color":"#111111","icons":[{"src":"/icons/Maskable.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"/icons/Circle.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/icons/Maskable-192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/icons/Circle-192.png","sizes":"192x192","type":"image/png","purpose":"any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
